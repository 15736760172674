import React from 'react';
import PropTypes from 'prop-types';
import pathContains from 'utils/pathContains';
import RenderForRoles from 'common/components/RenderForRoles';
import HeaderNavbarLink from './HeaderNavbarLink';
import FeatureFlag from 'featureFlags/FeatureFlag';
import { Space, Menu } from 'antd';
import { Dropdown } from 'InMarKit';
import { withRouter } from 'react-router';

class HeaderNavbar extends React.Component {
  getLinkActiveStatus(route) {
    return typeof route === 'string' && pathContains(this.props.location, route);
  }

  render() {
    return (
      <Space size="large" align="center" direction="horizontal">
        {this.props.sections.map(section => {
          let sectionRoute = section.children.map(child => {
            let childRoute = (
              <HeaderNavbarLink
                active={this.getLinkActiveStatus(child.route)}
                text={child.text}
                route={child.route}
                className={child.className}
              />
            );

            if (section.text) {
              childRoute = <Menu.Item key={child.route}>{childRoute}</Menu.Item>;
            }

            if (child.roles) {
              childRoute = <RenderForRoles roles={child.roles}>{childRoute}</RenderForRoles>;
            }

            if (child.flag) childRoute = <FeatureFlag flag={child.flag}>{childRoute}</FeatureFlag>;

            return childRoute;
          });

          if (section.text) {
            const selectedKeys = section.children
              .map(child => (this.getLinkActiveStatus(child.route) && child.route) || null)
              .filter(Boolean);
            const sectionClass = selectedKeys.length > 0 ? 'header__dropdown--current' : '';
            sectionRoute = (
              <Menu id="navbarSection" mode="horizontal" selectedKeys={selectedKeys}>
                {sectionRoute}
              </Menu>
            );
            sectionRoute = (
              <Dropdown
                icon="fas fa-caret-down"
                label={section.text}
                overlay={sectionRoute}
                className={sectionClass}
              />
            );
          }

          if (section.roles) {
            sectionRoute = <RenderForRoles roles={section.roles}>{sectionRoute}</RenderForRoles>;
          }

          if (section.flag)
            sectionRoute = <FeatureFlag flag={section.flag}>{sectionRoute}</FeatureFlag>;

          return sectionRoute;
        })}
      </Space>
    );
  }
}

HeaderNavbar.propTypes = {
  sections: PropTypes.array.isRequired,
  location: PropTypes.object,
};

export default withRouter(HeaderNavbar);
