import React from 'react';
import PropTypes from 'prop-types';
import { Affix, Col } from 'antd';
import HeaderNavbar from 'UPHeader/HeaderNavbar';
import HeaderUserMenu from 'UPHeader/HeaderUserMenu';
import { ROUTES } from 'constants/routeUrlConstants';
import RenderIf from 'common/components/RenderIf';
import Logger from 'utils/logger';
import { Header } from 'InMarKit';
import pathContains from 'utils/pathContains';
import './UPHeader.css';
import { withRouter } from 'react-router';
import { ADMIN_ROLES, NON_PLANNER_ROLES, ROLES } from 'constants/authConstants';
import { DISCOVERY_TOOL_URL } from 'constants/discoveryToolConstants';

const MAIN_NAV = [
  {
    roles: [ROLES.PLANNER_USER],
    children: [
      {
        text: 'TARGETING VISUALIZATION',
        route: ROUTES.TARGETING_VISUALIZATION,
        className: 'header__xl',
      },
      { text: 'AVAILS REQUEST', route: ROUTES.AVAILS_INVENTORY, className: 'header__xl' },
      { text: 'GEORETARGETING', route: ROUTES.CUSTOM_GEOCOOKIES, className: 'header__xl' },
      {
        text: 'TARGETING VISUALIZATION 2.0',
        route: ROUTES.TARGETING_VISUALIZATION_V2,
        flag: 'tviz_2_0',
        className: 'header__xl',
      },
      {
        text: 'DISCOVERY TOOL',
        route: () => window.open(DISCOVERY_TOOL_URL, '_blank'),
        flag: 'discoveryTool',
        className: 'header__xl',
      },
    ],
  },
  {
    roles: [ROLES.ANALYST_USER],
    children: [{ text: 'REPORTS', route: ROUTES.REPORTS }],
  },
  {
    text: 'ACCOUNT',
    roles: NON_PLANNER_ROLES,
    children: [
      {
        text: 'ADVERTISERS',
        route: ROUTES.ADVERTISERS_LIST,
      },
      {
        text: 'USERS',
        route: ROUTES.USERS_LIST,
        roles: ADMIN_ROLES,
      },
      {
        text: 'CLIENT TOOL',
        route: ROUTES.CLIENTS_LIST,
        roles: [ROLES.INTERNAL_ADMIN],
      },
      {
        text: 'RATE CARDS',
        route: ROUTES.RATE_CARD_LIST,
        roles: [ROLES.INTERNAL_ADMIN],
        flag: 'rateCardChanges',
      },
      {
        text: 'BILLING CONTACT',
        route: ROUTES.BILLING_CONTACT,
        roles: [ROLES.EXTERNAL_ADMIN],
      },
    ],
  },
  {
    text: 'Plan',
    roles: NON_PLANNER_ROLES,
    children: [
      { text: 'AVAILS REQUEST', route: ROUTES.AVAILS_INVENTORY },
      { text: 'GEORETARGETING', route: ROUTES.CUSTOM_GEOCOOKIES },
      { text: 'TARGETING VISUALIZATION', route: ROUTES.TARGETING_VISUALIZATION },
      {
        text: 'TARGETING VISUALIZATION 2.0',
        route: ROUTES.TARGETING_VISUALIZATION_V2,
        flag: 'tviz_2_0',
      },
    ],
  },
  {
    children: [
      { text: 'CAMPAIGN', route: ROUTES.CAMPAIGNS, roles: NON_PLANNER_ROLES },
      { text: 'REPORTS', route: ROUTES.REPORTS, roles: NON_PLANNER_ROLES },
    ],
  },
];

const HeaderComponent = ({ location }) => (
  <Affix>
    <Header productLink="/" titleLink="/" logoLink="/" product="Activation" title="GeoLink">
      <Col>
        <RenderIf condition={!pathContains(location, ROUTES.LOGIN)}>
          <HeaderNavbar sections={MAIN_NAV} />
        </RenderIf>
      </Col>
      <RenderIf condition={!PRODUCTION}>
        <Col>
          <div>Log ID {Logger.id}</div>
        </Col>
      </RenderIf>
      <Col className="user-menu">
        <HeaderUserMenu location={location} />
      </Col>
    </Header>
  </Affix>
);

HeaderComponent.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(HeaderComponent);
