import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'common/browserHistory';

import { ROUTES } from 'constants/routeUrlConstants';
import { SUPPORT_LINK, HELP_PORTAL_URL } from 'constants/helpLinkConstants';
import { logout } from 'auth/actions/authActions';
import pathContains from 'utils/pathContains';
import { ADMIN_ROLES } from 'constants/authConstants';
import ToggleSpinner from '../common/components/ToggleSpinner';
import HeaderNavbar from './HeaderNavbar';
import { isUserAsClientEnabled } from 'utils/sessionHelper';

const DEFAULT_MENU_LABEL = 'USER';
const DEFAULT_MENU_LABEL_AS_CLIENT = 'USER AS CLIENT';

export class HeaderUserMenu extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { isLoggingOut: false };

  getDisplayName(fullName, username, clientName) {
    if (isUserAsClientEnabled()) {
      if (clientName) {
        return `${clientName} User`;
      }
      return DEFAULT_MENU_LABEL_AS_CLIENT;
    }
    if (fullName && fullName.trim()) {
      return fullName;
    }
    if (username) {
      return username.split('@')[0];
    }
    return DEFAULT_MENU_LABEL;
  }

  logoutUser(e) {
    e.preventDefault();

    this.props.logout().then(redirectUri => {
      if (redirectUri) {
        this.setState({ isLoggingOut: true });
        window.location.href = redirectUri;
      } else {
        browserHistory.push(ROUTES.LOGIN);
      }
    });
  }

  render() {
    const { location, userId, full_name, username, client_name } = this.props;
    const displayName = this.getDisplayName(full_name, username, client_name);

    const GUEST_NAV = [
      {
        children: [
          { text: 'Help Portal', route: () => window.open(HELP_PORTAL_URL, '_blank') },
          { text: 'Support', route: () => window.open(SUPPORT_LINK, '_blank') },
        ],
      },
    ];

    const chooseUserClientMenuItem = {
      text: 'Choose User Client',
      route: () => window.open(`${ROUTES.CLIENT_SETUP}`, '_self'),
      roles: ADMIN_ROLES,
    };

    const USER_NAV = [
      {
        text: displayName,
        children: [
          {
            text: 'User Profile',
            route: () => window.open(`${ROUTES.USERS_LIST}/${userId}`, '_self'),
            roles: ADMIN_ROLES,
          },
          { text: 'Support', route: () => window.open(SUPPORT_LINK, '_blank') },
          { text: 'Help Portal', route: () => window.open(HELP_PORTAL_URL, '_blank') },
          isUserAsClientEnabled() ? chooseUserClientMenuItem : null,
          {
            text: 'Logout',
            condition: !pathContains(location, ROUTES.LOGIN),
            route: event => {
              this.logoutUser(event);
            },
          },
        ].filter(Boolean),
      },
    ];

    const nav = pathContains(location, ROUTES.LOGIN) ? GUEST_NAV : USER_NAV;
    return (
      <>
        {' '}
        <ToggleSpinner
          blocking
          opaque
          enabled={this.state.isLoggingOut}
          tip="Logging out..."
          className="center-fixed"
          size="x-large"
          data-test-id="sso-loading-spinner"
        />
        <HeaderNavbar sections={nav} />
      </>
    );
  }
}

HeaderUserMenu.propTypes = {
  location: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  userId: PropTypes.string,
  full_name: PropTypes.string,
  username: PropTypes.string,
  client_name: PropTypes.string,
};

const mapStateToProps = ({ auth: { user_id, full_name, username, client_name } }) => ({
  userId: user_id,
  full_name,
  username,
  client_name,
});

export default connect(mapStateToProps, { logout })(HeaderUserMenu);
