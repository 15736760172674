import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { kebabCase, lowerCase, startCase } from 'lodash';

const HeaderNavbarLink = ({ text, route, active, className }) => {
  let props = {};
  if (typeof route === 'function') {
    props.onClick = route;
  } else {
    props.to = route;
  }

  return (
    <Link
      {...props}
      data-test-id={`header__${kebabCase(text)}`}
      className={classNames(
        'header__btn',
        { 'header__btn--current': active },
        'header-link',
        className
      )}
    >
      {startCase(lowerCase(text))}
    </Link>
  );
};

HeaderNavbarLink.propTypes = {
  text: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default HeaderNavbarLink;
